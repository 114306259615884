/* eslint-disable */
let storage;

const createStorage = function() {
    return {
        items: {},
        getItem: function(key) {
            return this.items[key];
        },
        setItem: function(key, value) {
            this.items[key] = value;
        },
        removeItem: function(key) {
            this.items[key] = undefined;
            delete this.items[key];
        }
    };
};

const getStorage = () => {
    if (typeof window !== 'undefined') {
        storage = window.localStorage;
    }
    else {
        storage = createStorage();
    }
    return storage;
};

export default getStorage;
